import { useRef } from 'react';
import { useAnimateRef } from 'hooks/useAnimateRef';

export const Item = ({ parentClassName, item }) => {

  const { url, content } = item;
  const itemRef = useRef(null);

  useAnimateRef(itemRef);

  switch (content.type) {
    case 'image': return (
      <li
        className={`${parentClassName}__item`}
        ref={itemRef}
      >
        <a
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={content.image.source}
            alt={content.image.alternate}
          />
        </a>
      </li>
    );

    case 'email': return (
      <li
        className={`${parentClassName}__item`}
        ref={itemRef}
      >
        <a
          href={`mailto:${url}`}
          target='_blank'
          rel='noreferrer'
        >
          <span>{content.email}</span>
        </a>
      </li>
    );

    default: return (
      <li
        className={`${parentClassName}__item`}
        ref={itemRef}
      >
        <a
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          <span>{content.text}</span>
        </a>
      </li>
    );
  }
};