import { Header } from 'components/layout/Header';
import { Progress } from 'components/layout/Progress';
import { Routes, Route } from 'react-router-dom';
import { HomePage } from 'pages';
import { AboutPage } from 'pages';
import { PostsPage } from 'pages';
import { PostPage } from 'pages';
import { CardsPage } from 'pages';
import { MeditationsPage } from 'pages';
import { Contacts } from 'components/layout/Contacts';
import { ButtonMain } from 'components/layout/ButtonMain';
import { ModalSlider } from 'components/layout/ModalSlider';
import { Footer } from 'components/layout/Footer';

export const App = () => {
  return (
    <>
      {/* Header */}
      <Header />
      <Progress />
      {/* Routes */}
      <Routes>
        <Route path='*' element={<HomePage />} />
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/posts' element={<PostsPage />} />
        <Route path='/posts/:key' element={<PostPage />} />
        <Route path='/cards' element={<CardsPage />} />
        <Route path='/meditations' element={<MeditationsPage />} />
      </Routes>
      {/* Footer */}
      <Contacts />
      <ButtonMain />
      <ModalSlider />
      <Footer />
    </>
  );
};
