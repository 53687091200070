import { Column } from './components/Column';

const columns = [
  {
    links: [
      {
        url: '/assets/pdf/offer.pdf',
        content: {
          type: 'text',
          text: 'Публичная оферта на оказание услуг',
        }
      },
      {
        url: '/assets/pdf/policy.pdf',
        content: {
          type: 'text',
          text: 'Политика обработки персональных данных',
        }
      },
    ],
  },
  {
    links: [
      {
        url: '/assets/pdf/data-consent.pdf',
        content: {
          type: 'text',
          text: 'Согласие на обработку персональных данных ',
        }
      },
      {
        url: '/assets/pdf/email-consent.pdf',
        content: {
          type: 'text',
          text: 'Согласие на получение рассылки',
        }
      },
    ],
  }
];

export const Footer = () => {
  const className = 'footer';

  return (
    <footer className={className} id={'footer'}>
      <div className={`${className}__wrapper`}>
        <div className={`${className}__columns`}>
          {columns?.length > 0 && columns.map((column, index) => (
            <Column
              key={index}
              parentClassName={className}
              data={column}
            />
          ))}
        </div>
      </div>
    </footer>
  );
};