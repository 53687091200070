import Title from '../../../../common/Title';
import { List } from './components/List';

export const Column = ({ parentClassName, data }) => {
  if (!Object.keys(data).length) return null;

  return (
    <div className={`${parentClassName}__column`}>
      {data.title?.content && (
        <Title
          parentClassName={parentClassName}
          priority={data.title.priority}
        >
          {data.title.content}
        </Title>
      )}

      {data.links?.length > 0 && (
        <List
          parentClassName={parentClassName}
          list={data.links}
        />
      )}
    </div>
  );
};